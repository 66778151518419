import { Breadcrumbs, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
/**
 * Component Styles
 */
const useStyles = makeStyles((theme) => ({
  breadCrumbsRoot: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  breadCrumbsSeparator: {
    marginLeft: "0px",
    marginRight: "0px",
  },
  linkRoot: {
    color: "#31afac",
  },
  breadCrumbsRootDesktop: {
    marginLeft: "6px",
    fontSize: "24px",
    lineHeight: "normal",
    fontWeight: "400",
  },
  breadCrumbsSeparatorDesktop: {
    marginLeft: "2px",
    marginRight: "2px",
    color: "#000",
  },
  linkRootDesktop: {
    color: "#31afac",
    fontSize: "20px",
  },
}));

/**
 * Breadcrumbs component for mobile SVN pages
 *
 * @param {object} root0  props passed to the component .
 * @param {Array} root0.breadcrumbs breadcrumbs data.
 *
 * @returns {React.ReactElement} - returns the breadcrumbs component.
 */
const SVNBreadCrumbs = ({ breadcrumbs }) => {
  const classes = useStyles();
  const { isMobile } = useSelector((state) => state.appConfigs);

  return (
    <Grid className="navigation" container>
      <Breadcrumbs
        classes={{
          root: isMobile ? classes.breadCrumbsRoot : classes.breadCrumbsRootDesktop,
          separator: isMobile ? classes.breadCrumbsSeparator : classes.breadCrumbsSeparatorDesktop,
        }}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs?.map(({ label, url }, index) => {
          return breadcrumbs.length - 1 > index ? (
            <Link key={label} color="textPrimary" href={url} data-testid="rootLink">
              {label}
            </Link>
          ) : (
            <Typography
              key={label}
              classes={{
                root: isMobile ? classes.linkRoot : classes.linkRootDesktop,
              }}
            >
              {label}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Grid>
  );
};

SVNBreadCrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default SVNBreadCrumbs;
